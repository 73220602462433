import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "./Layout/Layout";
import { useForm } from "react-hook-form";
import { Form, Button } from "semantic-ui-react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Select from "react-select";
//import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  getValidatedCustomerNumber,
  addUserDetails,
  addIdNumber,
  newSetRegistrationType,
  addNewUserDetails,
  addCustomerValidateDetails,
  addIdType,
} from "../redux/actions/registerCustomerAction";

export const Validation = () => {
  const handleClose = () => setShow(false);
 
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const ID_Type = useSelector((state) => state.addNewUserDetailsReducer.idType);
  const ID_Name = useSelector((state) => state.addNewUserDetailsReducer.idName);

  const reputableDetails = useSelector(
    (state) => state.addNewReputablePersonDetails.reputableDetails
  );

  const customerDetails = useSelector(
    (state) => state.addNewUserDetailsReducer.validateCustomerDetails
  );
  // console.log('id_Num',id_Num);

  const id_Num = customerDetails !== null ? customerDetails.id_Num : null;
  // console.log('id_Num',id_Num);

  const cus_Num = customerDetails !== null ? customerDetails.cus_Num : null;
  const id_type = ID_Type !== null ? ID_Type : null;
  const id_Name = ID_Name !== null ? ID_Name : null;

  const [customerNumber, setCustomerNumber] = useState(
    cus_Num !== undefined ? cus_Num : ""
  );
  const [idType, setIDType] = useState(id_type !== null ? id_type : "");
  const [idNumber, setIdNumber] = useState(id_Num !== undefined ? id_Num : "");
  const [selectedIDType, setSelectedIDType] = useState(null);
  const [idName, setIDName] = useState(id_Name !== null ? id_Name : "");

  const [selectedRegistrationType, setSelectedRegistrationType] = useState(
    reputableDetails !== null
      ? {
          value: reputableDetails.registrationType,
          label: reputableDetails.registrationType,
        }
      : null
  );

  const [idList, setIdList] = useState([]);

  const [registrationType, setRegistrationType] = useState(
    reputableDetails !== null ? reputableDetails.registrationType : null
  );
  const [isReputable, setIsReputable] = useState(
    reputableDetails !== null
      ? reputableDetails.isReputablePerson === 0
        ? 0
        : 1
      : 0
  );

  // console.log("isreptuable",isReputable)
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    dispatchIDNumber(idNumber);
    _dispatchValidatedNumber(customerNumber);
    // dispatchIdType(idType);
    dispatchRegistrationType();
    dispatchUserDetails();
    dispatch(addIdType(idType, idName));
    // dispatch(addIdType(idType.label));
    // dispatch(addIdType(idType));

      await axios({
        url: "https://mpsreg-api.azurewebsites.net/api/registration/fetchcheckmsisdn",
        method: "POST",
        data: {
          api_key: "YouShallPass",
          TID: "12345",
          number: customerNumber,
          idType: idType,
          id: idNumber,
          api_key: "YouShallPass",
        },
      })
        .then((response) => {
          if (
            response.data.data.qualifiesRegistration === 1 ||
            response.data.data.qualifiesRegistration === 2
          ) {
            navigate("/userDetails");
          } else if (response.data.data.qualifiesRegistration === 3) {
            alert("Cannot register customer exisiting! Please try again.");
          } else if (response.data.data.qualifiesRegistration === 4) {
            alert("Exceeds Max registration limit");
          } else {
            alert("registration failed.");
          }
        })
        .catch((e) => {
          alert(
            "Cannot register customer! Please try again or contact customer care."
          );
        });
    
  };

  async function getIdList() {
    axios({
      url: "https://mpsreg-api.azurewebsites.net/api/registration/getIdList",
      method: "POST",
      data: {
        api_key: "YouShallPass",
      },
    }).then((response) => {
      // console.log(response.data.data);
      let list = response.data.data[0];
      let newList = list.map((ids) => {
        let newSet = {};
        newSet.value = ids.ID_Type;
        newSet.label = ids.ID_Name;
        return newSet;
      });

      if (ID_Type !== null) {
        let idFilter = newList.filter((id) => {
          if (id.value === idType) {
            return id;
          }
        });

        setSelectedIDType(idFilter[0]);
      }
      setIdList(newList);
    });
  }

  useEffect(() => {
    getIdList();
  }, []);

  const saveValidateCustomerForm = () => {
    // let validateCustomerDetails = {};
    // validateCustomerDetails.Num = customerNumber;
    // validateCustomerDetails.ID = idNumber;
    // dispatch(getValidatedCustomerNumber(customerNumber));
    // dispatch(addIdNumber(idNumber));
    // dispatchIDNumber(idNumber);
    // _dispatchValidatedNumber(customerNumber);
    //dispatchRegistrationType();
    //dispatchUserDetails();
    //dispatch(addIdType(idType));
    //dispatch(validateCustomer(validateCustomerDetails))
  };

  const handleIDChange = (idType, idName) => {
    setIDType(idType.value);
    setIDName(idType.label);
    setSelectedIDType(idName);
  };

  // const idNameChange = (IDName) => {
  //   setIDName(IDName);
  // };

  const handleRegistrationChange = (e) => {
    setRegistrationType(e.value);
    setSelectedRegistrationType(e);
    // console.log(registrationType);
  };

  const _onChangeCusNumber = (cusNum) => {
    setCustomerNumber(cusNum);
  };

  const idNumberChange = (idNumber) => {
    setIdNumber(idNumber);
  };

  const dispatchUserDetails = () => {
    let customerDetails = {};
    customerDetails.cus_Num = customerNumber;
    customerDetails.id_Num = idNumber;
    //customerDetails.id_Name = idName;
    dispatch(addCustomerValidateDetails(customerDetails));
  };
  const dispatchRegistrationType = () => {
    let reputableDetailsAdd = {};
    let isReputableBinary = isReputable ? 1 : 0;
    reputableDetailsAdd.isReputablePerson = isReputableBinary;
    reputableDetailsAdd.registrationType = registrationType;

    dispatch(newSetRegistrationType(reputableDetailsAdd));
  };
  const _dispatchValidatedNumber = () => {
    dispatch(getValidatedCustomerNumber(customerNumber));
  };

  const dispatchIDNumber = () => {
    dispatch(addIdNumber(idNumber));
  };

  return (
    <Layout>
      <div className="card-Pg2">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <center>
              <h2 className="Auth-form-title"> Validate Customer</h2>
            </center>

            <label htmlFor="exampleInputEmail1" className="form-label">
              Customer Number
            </label>
            <input
              type="text"
              pattern="[0-9]*"
              value={customerNumber}
              {...register("customerNumber", { required: true, maxLength: 7 })}
              onChange={(input) => {
                _onChangeCusNumber(input.target.value);
              }}
              className="form-control"
              aria-describedby="emailHelp"
            />
            {errors.customerNumber && (
              <p className="text-error" style={{ fontSize: "12px" }}>
                Please check the Customer Number
              </p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              ID Type
            </label>
            <Select
              //  {...register("selectedIDType", { required: true })}
              onChange={(e) => handleIDChange(e)}
              options={idList}
              value={selectedIDType}
            />
          </div>

          <div>
            <label htmlFor="exampleInputEmail1" className="form-label">
              ID Number
            </label>
            <input
              type="text"
              value={idNumber}
              {...register("idNumber", { required: true, maxLength: 12 })}
              onChange={(e) => idNumberChange(e.target.value)}
              className="form-control"
              aria-describedby="emailHelp"
            />
            {errors.idNumber && (
              <p className="text-error" style={{ fontSize: "12px" }}>
                Please check the ID Number
              </p>
            )}
            <FormControlLabel
              onChange={() => {
                setIsReputable(isReputable === 0 ? 1 : 0);
              }}
              control={<Checkbox size="small" />}
              label={<span style={{ fontSize: "15px" }}>{} "Register with Parent/Guardian/Endoser"</span>}
              checked={isReputable === 0 ? false : true}
            />

            {isReputable === 1 && (
              <div>
                <br />
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Registration Type
                </label>

                <Select
                  onChange={(e) => handleRegistrationChange(e)}
                  value={selectedRegistrationType}
                  options={[
                    { label: "Parent", value: 1 },
                    { label: "Guardian", value: 2 },
                    { label: "Endorser", value: 3 },
                  ]}
                />
              </div>
            )}
            <div className="spacing">
                    <Link to="/Registrationtype">
                      <button className="btn btn-danger">Back</button>
                    </Link>

                    <Button
                      className=" btn btn-danger"
                      type="submit"
                    >
                      Next
                    </Button>
              </div>
          </div>
        </Form>
      </div>
    </Layout>
  );
};

let idListJson = [
  {
    ID_Type: 1,
    ID_Name: "Passport",
  },
  {
    ID_Type: 2,
    ID_Name: "FNPF Card",
  },
  {
    ID_Type: 3,
    ID_Name: "Drivers License",
  },
  {
    ID_Type: 4,
    ID_Name: "Military ID Card",
  },
  {
    ID_Type: 5,
    ID_Name: "Police ID Card",
  },
  {
    ID_Type: 6,
    ID_Name: "Birth Certificate",
  },
  {
    ID_Type: 7,
    ID_Name: "Reference Letter",
  },
  {
    ID_Type: 11,
    ID_Name: "Marriage Certificate",
  },
  {
    ID_Type: 12,
    ID_Name: "Social Welfare Card",
  },
  {
    ID_Type: 13,
    ID_Name: "Citizenship Certificate",
  },
  {
    ID_Type: 14,
    ID_Name: "VNKB Certificate",
  },
  {
    ID_Type: 15,
    ID_Name: "Photo",
  },
  {
    ID_Type: 16,
    ID_Name: "Student ID",
  },
  {
    ID_Type: 17,
    ID_Name: "Employee ID",
  },
  {
    ID_Type: 9,
    ID_Name: "Voter Registration",
  },
  {
    ID_Type: 10,
    ID_Name: "Others",
  },
  {
    ID_Type: 18,
    ID_Name: "FIRCA",
  },
  {
    ID_Type: 19,
    ID_Name: "Payslip",
  },
  {
    ID_Type: 20,
    ID_Name: "Extract Proof of Registration (Vola ni Kawabula)",
  },
  {
    ID_Type: 21,
    ID_Name: "FNPF/FRCA Card",
  },
];
