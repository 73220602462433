import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import LoginLayout from "./Layout/LoginLayout";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { addUsername } from "../redux/actions/registerCustomerAction";
import mpaisa from "../Assets/mpaisa2.jpeg";

const md5 = require("md5");              

export const Login = () => {
const saveUsername = useSelector((state) => state.addNewUserDetailsReducer.username);
const agentUsername = saveUsername !== null ? saveUsername.username : null;
const navigate = useNavigate();
const [username, setUsername] = useState(agentUsername !== null ? agentUsername : "");
const [password, setPassword] = useState("");


const dispatch = useDispatch();

  const validateInputs = (username,password) => {
    
    let isValid = true;

    if (username == null ||username.length == 0|| username.length < 5) {
      alert("Username is required and should be at least 5 characters long");
      isValid = false;
    }

    if (password == null ||password.length == 0|| password.length < 1) {
      alert("Password is required and should be at least 5 characters long");
      isValid = false;
    }

    return isValid;
  };

  const submitLoginForm = async (event) => {
    event.preventDefault();

    if (!validateInputs(username, password)) {
      return;
    }
    else {
    const formElement = document.querySelector("#loginForm");
    const formData = new FormData(formElement);
    // const formDataJSON = Object.fromEntries(formData);
    const btnPointer = document.querySelector("#login-btn");
    btnPointer.innerHTML = "Please wait..";
    btnPointer.setAttribute("disabled", true);

    let hashedPass = md5(password + "MPSApp_CA").toUpperCase();

    await axios
      .post(
        "https://mpsreg-api.azurewebsites.net/api/registration/registrationLogin",
        // "http://localhost:3001/api/registration/registrationLogin", 

        {
          username: username,
          password: hashedPass,
          api_key: "YouShallPass",
        },
        { rejectUnauthorized: false }
      )
      .then((response) => {
        btnPointer.innerHTML = "Login";
        btnPointer.removeAttribute("disabled");

        sessionStorage.clear();
        sessionStorage.setItem("user-token", response.data.response);

        console.log(response)

        if (response.data.response === 1001) {
          navigate("/Registrationtype");
        } else if (response.data.response === 1002) { 
          alert("Incorrect password. Please Enter Correct Password.");
        } 
        else {
          alert("Unable to login. Please try after some time.");
          setPassword("");
        }
      })
      .catch((error) => {
        btnPointer.innerHTML = "Login";
        btnPointer.removeAttribute("disabled");
        alert("Oops! Some error occured.");
      });
    dispatch(addUsername(username));
  };
  }
  return (
    // <Layout>
    <React.Fragment>
      <LoginLayout>
        <div className="cardfinal">
          <form id="loginForm" onSubmit={submitLoginForm}>
            <div className="Auth-form-content">
              <div className="side-by-side mt-4">
                <img
                  src={mpaisa}
                  width="40%"
                  alt="Logo"
                  className="margin-right-7"
                />
                <br></br>
                <Typography variant="h4" component="h3">
                  M-PAiSA Registration
                </Typography>
              </div>

              <div className="form-group mt-3">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Type Username"
                />
                <label className="mt-3">Password</label>
                <input
                  type="password"
                  className="form-control mt-1"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                >
                </input>
                {/* <div>
                  <Link to="/changepassword">Change Password</Link>
                </div> */}
                <div>
                  {" "}
                  <button id="login-btn" className=" btn btn-danger mt-4">
                    {" "}
                    Login{" "}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </LoginLayout>
    </React.Fragment>
  );
};
