import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Button } from "semantic-ui-react";
import { addRegistrationType } from "../redux/actions/registerCustomerAction";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Box,
  Typography,
} from "@mui/material";
import Layout from "./Layout/Layout";

const RegistrationType = () => {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const RegTypeReducer = useSelector((state) => state.addNewUserDetailsReducer.RegistrationType);
  
  const RType = RegTypeReducer ? RegTypeReducer : "";
  const [RegType, setRegType] = useState(RType !== null ? RType : "");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log('RegType',RegType,typeof(RegType))

  useEffect(() => {
    if (RegType === "1") {
      // setDescription("Register and get a new SIM card.");
    } else if (RegType === "2") {
      // setDescription("New SIM & M-PAiSA Registration.");
    } else {
      // setDescription("");
    }
  }, [RegType]);

  const onSubmit = (data) => {
    if (!RegType) {
      alert("Please select a registration method before proceeding.");
      return;
    }
    dispatch(addRegistrationType(RegType));
    navigate("/validation");
  };
  

  return (
    <Layout>
      <Box className="card-Pg3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box className="mb-3">
            <center>
              <h2 className="Auth-form-title">Registration Method</h2>
            </center>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <RadioGroup
                defaultValue="simWithMpaisa"
                value={RegType}
                name="registrationType"
                onChange={(e) => setRegType(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio {...register("registrationType")} />}
                  label="SIM registration"
                />
                {RegType === "1" && (
                  <Typography variant="body2" sx={{ ml: 3, fontSize: '0.80rem', mt: 1, mb: 1 }}>
                    Register and get a new SIM card.
                  </Typography>
                )}
                <FormControlLabel
                  value="2"
                  control={<Radio {...register("registrationType")} />}
                  label="SIM registration With M-PAiSA"
                />
                {RegType === "2" && (
                  <Typography variant="body2" sx={{ ml: 3, fontSize: '0.80rem', mt: 1, mb: 5 }}>
                    New SIM & M-PAiSA Registration.
                  </Typography>
                )}
              </RadioGroup>
            </FormControl>
            <Box className="mt-3 text-center">
              <Button type="submit" className="btn btn-danger">
                Submit
              </Button>
            </Box>
          </Box>
        </Form>
      </Box>
    </Layout>
  );
};

export default RegistrationType;
