import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./form/ProtectedRoute";

// Import your form components
import { Login } from "./form/loginForm";
import { Validation } from "./form/validationForm";
import { UserDetails } from "./form/userDetailsForm";
import { SecurityQuestions } from "./form/securityQuestionsForm";
import { PhotoUpload } from "./form/photoUploadForm";
import { SignatureForm } from "./form/signatureForm";
import { ConfirmUserDetails } from "./form/confirmUserDetails";
import { UploadID } from "./form/idUploadForm";
import { ParentGuardianEndorser } from "./form/parentGuardianEndorser";
import { Changepassword } from "./form/changepassword";
import RegistrationType from "./form/RegistrationType"

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/Registrationtype" element={<ProtectedRoute><RegistrationType /></ProtectedRoute>} />

        <Route path="/validation" element={<ProtectedRoute><Validation /></ProtectedRoute>} />

        <Route path="/changepassword" element={<ProtectedRoute><Changepassword /></ProtectedRoute>} />

        <Route path="/userDetails" element={<ProtectedRoute><UserDetails /></ProtectedRoute>} />

        <Route path="/securityQuestions" element={<ProtectedRoute><SecurityQuestions /></ProtectedRoute>} />

        <Route path="/uploadID" element={<ProtectedRoute><UploadID /></ProtectedRoute>} />

        <Route path="/uploadPhoto" element={<ProtectedRoute><PhotoUpload /></ProtectedRoute>} />

        <Route path="/signature" element={<ProtectedRoute><SignatureForm /></ProtectedRoute>} />

        <Route path="/confirmDetails" element={<ProtectedRoute><ConfirmUserDetails /></ProtectedRoute>} />

        <Route path="/ParentGuardianEndorser" element={<ProtectedRoute><ParentGuardianEndorser /></ProtectedRoute>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
