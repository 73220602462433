import RegistrationType from "../../form/RegistrationType";
import {
  ADD_USER_DETAILS_FORM,
  CLEAR_NEW_USER_DETAILS,
  VALIDATE_CUSTOMER_NUMBER,
  ID_TYPE,
  USERNAME,
  REGISTRATION_TYPE
} from "../actions/types";

const initialState = {
  newUserDetailsForm: null,
  validateCustomerDetails: null,
  idType: null,
  idName: null,
  username:null,
  RegistrationType:null
};

const addNewUserDetailsReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case USERNAME:
      return {
        ...prevState,
        username: action.username,
      };
    case ADD_USER_DETAILS_FORM:
      //console.log('here');
      return {
        ...prevState,
        newUserDetailsForm: action.newUserDetailsForm,
      };
    case VALIDATE_CUSTOMER_NUMBER:
      return {
        ...prevState,
        validateCustomerDetails: action.validateCustomerDetails,
      };
    case ID_TYPE:
      return {
        ...prevState,
        idType: action.idType,
        idName: action.idName,
      };
      
      case REGISTRATION_TYPE:
        return {
          ...prevState,
          RegistrationType: action.RegistrationType,
        };

  case CLEAR_NEW_USER_DETAILS:
      return {
        ...initialState, // Preserve the username
        username: prevState.username,
      };
    default:
      return prevState;
  }
};

export default addNewUserDetailsReducer;
